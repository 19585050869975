/**
 * @type {import('../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/js/router.js').Router}
 */
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router';
import routes from '../../../public/js/fos_js_routes.json';
import Storage from './Storage';

Routing.setRoutingData(routes);
Routing.setLocale(Storage.getLocale());

export default Routing;