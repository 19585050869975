let localeKey = '_locale';
let defaultLocale = 'fr';

export function getLocale() {
    let locale = window.localStorage.getItem(localeKey);

    if (null === locale || 0 === locale.length) {
        setLocale(defaultLocale);
        return defaultLocale;
    }

    return locale;
}

export function setLocale(localeValue) {
    window.localStorage.setItem(localeKey, localeValue);
}

export default {getLocale, setLocale}